import { render, staticRenderFns } from "./SignUpEnterEmail.vue?vue&type=template&id=0af3d358&scoped=true"
import script from "./SignUpEnterEmail.vue?vue&type=script&lang=ts&setup=true"
export * from "./SignUpEnterEmail.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SignUpEnterEmail.vue?vue&type=style&index=0&id=0af3d358&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af3d358",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedImageBlockiesAvatar: require('/root/build_gs/core/components/composed/image/BlockiesAvatar.vue').default,BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedFormItemInput: require('/root/build_gs/core/components/composed/form-item/Input.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
