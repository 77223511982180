import { render, staticRenderFns } from "./Close.vue?vue&type=template&id=337438f9&scoped=true"
import script from "./Close.vue?vue&type=script&setup=true&lang=ts"
export * from "./Close.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Close.vue?vue&type=style&index=0&id=337438f9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337438f9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default})
