import { render, staticRenderFns } from "./InCard.vue?vue&type=template&id=3691d6fc&scoped=true"
import script from "./InCard.vue?vue&type=script&setup=true&lang=ts"
export * from "./InCard.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InCard.vue?vue&type=style&index=0&id=3691d6fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3691d6fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseCountdownSimple: require('/root/build_gs/core/components/base/countdown/Simple.vue').default})
