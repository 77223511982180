import type PromosState from "./state";

export type State = ReturnType<typeof PromosState>;

export enum PromosNamesEnum {
    THIRD_ANNIVERSARY = 'thirdAnniversary',
    GLOBAL_POOL = 'globalPool',
    DIRECT_SALES= 'directSalesIncentive',
    PRODUCT_AUTOSHIP = 'productAutoShip',
    RANK_ACHIEVEMENT = 'rankAchievement',
    CASH_PROMO = 'cashPromo',
    FAST_START = 'fastStart',
    SPRING_INCENTIVE = 'springIncentive',
    MYSTERY_BOX = 'mysteryBox',
    TRAVEL = 'travel',
    TURNOVER = 'turnover',
    LEVEL_POOL = 'level_pool',
    TURNOVER_POOL = 'turnover_pool',
    GLOBAL_LINE_BONUS = 'global_line_bonus',
}

export type PromoItemType = {
    id: PromosNamesEnum,
    promoImage: string,
    href: string,
    title: string,
    expiresAt: number | string | Date,
    rewards: string | number,
    isLoopedPromo?: boolean,
    noExpiresDate?: boolean,
    status?: boolean,
    metaverseIds?:number[]
}
