import { render, staticRenderFns } from "./IconInfo.vue?vue&type=template&id=8b00c52a&scoped=true"
import script from "./IconInfo.vue?vue&type=script&setup=true&lang=ts"
export * from "./IconInfo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./IconInfo.vue?vue&type=style&index=0&id=8b00c52a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b00c52a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseCardSideSplit: require('/root/build_gs/core/components/base/card/SideSplit.vue').default})
