import { render, staticRenderFns } from "./AuthButtons.vue?vue&type=template&id=38a8ffcf&scoped=true"
import script from "./AuthButtons.vue?vue&type=script&setup=true&lang=ts"
export * from "./AuthButtons.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a8ffcf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedActionDecisionButtons: require('/root/build_gs/core/components/composed/action/DecisionButtons.vue').default})
