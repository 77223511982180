import { render, staticRenderFns } from "./Secondary.vue?vue&type=template&id=57eb0634&scoped=true"
import script from "./Secondary.vue?vue&type=script&setup=true&lang=ts"
export * from "./Secondary.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Secondary.vue?vue&type=style&index=0&id=57eb0634&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57eb0634",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIndicatorBadge: require('/root/build_gs/core/components/base/indicator/Badge.vue').default,BaseMenuItemContent: require('/root/build_gs/core/components/base/menu-item/Content.vue').default,BaseLinkMenu: require('/root/build_gs/core/components/base/link/Menu.vue').default,BaseMenuItemContainer: require('/root/build_gs/core/components/base/menu-item/Container.vue').default})
