import { render, staticRenderFns } from "./ThemeToggle.vue?vue&type=template&id=ab020254&scoped=true"
import script from "./ThemeToggle.vue?vue&type=script&setup=true&lang=ts"
export * from "./ThemeToggle.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab020254",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSwitchMonochromeIconedToggle: require('/root/build_gs/core/components/base/switch/MonochromeIconedToggle.vue').default})
