import { render, staticRenderFns } from "./MobileLanguages.vue?vue&type=template&id=04602859&scoped=true"
import script from "./MobileLanguages.vue?vue&type=script&setup=true&lang=ts"
export * from "./MobileLanguages.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MobileLanguages.vue?vue&type=style&index=0&id=04602859&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04602859",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedMenuLanguagesSubMenu: require('/root/build_gs/core/components/composed/menu/LanguagesSubMenu.vue').default,BaseSidebarSliding: require('/root/build_gs/core/components/base/sidebar/Sliding.vue').default,BaseOverlaySimple: require('/root/build_gs/core/components/base/overlay/Simple.vue').default})
