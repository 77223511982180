import { render, staticRenderFns } from "./NotificationsLoadingError.vue?vue&type=template&id=9db57c2a"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedPlaceholderErrorWithButton: require('/root/build_gs/core/components/composed/placeholder/ErrorWithButton.vue').default})
