import { render, staticRenderFns } from "./NoCloseButtonAffility.vue?vue&type=template&id=76a06ac8&scoped=true"
import script from "./NoCloseButtonAffility.vue?vue&type=script&setup=true&lang=ts"
export * from "./NoCloseButtonAffility.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NoCloseButtonAffility.vue?vue&type=style&index=0&id=76a06ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76a06ac8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageAffilityLogo: require('/root/build_gs/core/components/base/image/AffilityLogo.vue').default,BasePopupBasic: require('/root/build_gs/core/components/base/popup/Basic.vue').default})
