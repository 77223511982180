import { render, staticRenderFns } from "./LanguagesDropdown.vue?vue&type=template&id=23cf712e&scoped=true"
import script from "./LanguagesDropdown.vue?vue&type=script&lang=ts&setup=true"
export * from "./LanguagesDropdown.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23cf712e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,ComposedListLanguagesSwitch: require('/root/build_gs/core/components/composed/list/LanguagesSwitch.vue').default,BaseDropdownSimple: require('/root/build_gs/core/components/base/dropdown/Simple.vue').default})
