import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=3216a8e0"
import script from "./empty.vue?vue&type=script&setup=true&lang=ts"
export * from "./empty.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./empty.vue?vue&type=style&index=0&id=3216a8e0&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedButtonSupport: require('/root/build_gs/core/components/composed/button/Support.vue').default})
