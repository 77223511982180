import { render, staticRenderFns } from "./Affiliate.vue?vue&type=template&id=66d768d1&scoped=true"
import script from "./Affiliate.vue?vue&type=script&setup=true&lang=ts"
export * from "./Affiliate.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d768d1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedWrapperAsyncWithLoadingAndError: require('/root/build_gs/core/components/composed/wrapper/AsyncWithLoadingAndError.vue').default})
