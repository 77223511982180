import { render, staticRenderFns } from "./Theme.vue?vue&type=template&id=22bd3720"
import script from "./Theme.vue?vue&type=script&setup=true&lang=ts"
export * from "./Theme.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIconSwitch: require('/root/build_gs/core/components/base/icon/Switch.vue').default,BaseSwitchModeButton: require('/root/build_gs/core/components/base/switch/ModeButton.vue').default})
