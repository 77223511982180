import { render, staticRenderFns } from "./SignInWithEmail.vue?vue&type=template&id=52d554b2&scoped=true"
import script from "./SignInWithEmail.vue?vue&type=script&setup=true&lang=ts"
export * from "./SignInWithEmail.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SignInWithEmail.vue?vue&type=style&index=0&id=52d554b2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d554b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedFormItemInput: require('/root/build_gs/core/components/composed/form-item/Input.vue').default,ComposedFormItemPassword: require('/root/build_gs/core/components/composed/form-item/Password.vue').default,FlowsSigninStepsCloudflareTurnstile: require('/root/build_gs/core/components/flows/signin/steps/CloudflareTurnstile.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,BaseDividerText: require('/root/build_gs/core/components/base/divider/Text.vue').default})
